<template>
  <div>
    <NuxtLink
      v-for="appLink in props.links"
      :key="appLink.id"
      :to="`${appLink?.linkTo}?platform=${appLink.appType}`"
      class="inline-block p-1 text-dynamic-primary"
      :data-test-id="`download-icon-${appLink.appType}`"
      :class="`app-badge-${appLink.appType}`"
    >
      <NuxtImg
        :src="appLink?.appType === 'ios' ? BadgeAppStore : BadgeGooglePlay"
        :alt="`${t('editorial.download_app_for')} ${appLink.appType}`"
        class="inline-block"
        width="150"
        height="50"
      />
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import BadgeAppStore from '~/assets/logos/badge-app_store.svg?url';
import BadgeGooglePlay from '~/assets/logos/badge-google_play.svg?url';

interface AppLink {
  id: number;
  appType: string;
  linkTo: string;
}

const { t } = useI18n();
const props = defineProps({
  links: {
    type: Array as PropType<AppLink[]>,
    default: () => [],
  },
});
</script>
